<script setup>
import { useAuthStore } from '@/stores/auth'
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const authStore = useAuthStore()
const isSignedIn = computed(() => authStore.isSignedIn)
const isMenuOpen = ref(false)

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value
}

const currentPath = computed(() => route.fullPath)

const navLinks = [
  { to: '/wizy', label: 'Wizy' },
  { to: '/legalizacje', label: 'Legalizacje' },
  { to: '/dokumenty-i-uslugi', label: 'Dokumenty i usługi' }
]

const isActiveLink = (path) => {
  return currentPath.value.startsWith(path)
}
</script>
<template>
  <nav class="sticky top-0 z-10 w-full h-16 text-sm md:h-18 bg-mainGrey-700 text-mainGrey-200">
    <div class="flex items-center justify-between h-full px-4 mx-auto md:px-10 lg:px-20 max-w-7xl">
      <div class="flex items-center">
        <router-link to="/" class="mr-16">
          <img src="@/assets/logo-notberg.svg" alt="logo" class="w-[6rem] h-16" />
        </router-link>
        <div class="items-center text-md hidden md:flex gap-x-8">
          <router-link
            v-for="link in navLinks"
            :key="link.to"
            :to="link.to"
            class="hover:text-white"
            :class="{ 'text-mainGreen-700': isActiveLink(link.to) }"
          >
            {{ link.label }}
          </router-link>
        </div>
      </div>
      <div class="items-center hidden md:flex gap-x-4">
        <template v-if="isSignedIn">
          <router-link class="hover:text-white" to="/moje-konto">Twoje konto</router-link>
          <router-link class="hover:text-white" to="/zamowienia">Zamówienia</router-link>
          <button @click="authStore.signOut" class="hover:text-white">Wyloguj</button>
        </template>
        <template v-else>
          <router-link class="hover:text-white" to="/rejestracja">Rejestracja</router-link>
          <router-link class="hover:text-white" :to="`/logowanie?redirect=${currentPath}`">
            Zaloguj
          </router-link>
        </template>
      </div>
      <button @click="toggleMenu" class="text-white md:hidden">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-6 h-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>

    <!-- Mobile menu -->
    <div v-if="isMenuOpen" class="md:hidden bg-mainGrey-800 divide-y divide-mainGrey-700">
      <router-link
        v-for="link in navLinks"
        :key="link.to"
        :to="link.to"
        class="block px-12 py-4 text-white"
        :class="{ 'text-mainGreen-500': isActiveLink(link.to) }"
        @click="toggleMenu"
      >
        {{ link.label }}
      </router-link>
      <template v-if="isSignedIn">
        <router-link to="/zamowienia" class="block text-white px-12 py-4" @click="toggleMenu">
          Zamówienia
        </router-link>
        <router-link to="/moje-konto" class="block text-white px-12 py-4" @click="toggleMenu">
          Twoje konto
        </router-link>
        <button @click="authStore.signOut" class="block w-full text-left text-white px-12 py-4">
          Wyloguj
        </button>
      </template>
      <template v-else>
        <router-link to="/rejestracja" class="block px-12 py-4 text-white" @click="toggleMenu">
          Rejestracja
        </router-link>
        <router-link
          to="/logowanie"
          class="block px-12 py-4 text-white bg-mainGrey-700"
          @click="toggleMenu"
        >
          Zaloguj
        </router-link>
      </template>
    </div>
  </nav>
</template>

<style scoped>
.router-link-active {
  color: #5baa80;
}
</style>
