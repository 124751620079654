<script setup>
import { ref, onMounted, computed } from 'vue'
import MainButton from '@/components/MainButton.vue'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'vue-chartjs'
import { chartOptions } from '@/lib/charts'
import {
  getArticles as getApiArticles,
  getSocialMediaPosts as getApiSocialMediaPosts,
  getChartVisaKinds as getApiChartVisaKinds
} from '@/lib/requests'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
import { differenceInDays, parse } from 'date-fns'
import { PlayCircleIcon } from '@heroicons/vue/24/solid'

ChartJS.register(ArcElement, Tooltip, Legend)

const router = useRouter()
const articles = ref([])
const displayArticles = computed(() => articles.value.slice(0, 3))
const socialMediaPosts = ref([])

async function getArticles() {
  const response = await getApiArticles()
  const data = await response.json()

  articles.value = data
}

async function getSocialMediaPosts() {
  const response = await getApiSocialMediaPosts()
  socialMediaPosts.value = await response.json()
}
import facebook from '@/assets/icons/facebook.svg'
import linkedin from '@/assets/icons/linkedin.svg'
import youtube from '@/assets/icons/youtube.svg'

function getIconAndText(platform) {
  const icons = {
    facebook: facebook,
    linkedin: linkedin,
    youtube: youtube
  }
  const texts = {
    facebook: 'zobacz post',
    linkedin: 'zobacz post',
    youtube: 'zobacz film'
  }
  return { icon: icons[platform], text: texts[platform] }
}

const charVisaKinds = ref(null)

async function getChartData() {
  const response = await getApiChartVisaKinds()
  charVisaKinds.value = await response.json()
}

const chartData = computed(() => {
  return {
    labels: charVisaKinds?.value?.map((item) => t(`chart.labels.${item.label}`)),
    datasets: [
      {
        // backgroundColor: ['#FBDF80', '#EA942D', '#9F580A', '#2377AA', '#8AE4F4', '#158E4E'],
        backgroundColor: ['#FBDF80', '#2377AA', '#8AE4F4', '#158E4E'],
        borderColor: ['transparent', 'transparent'],
        data: charVisaKinds?.value?.map((item) => item.data)
      }
    ]
  }
})

function daysToNow(dateString) {
  const date = parse(dateString, 'yyyy-MM-dd', new Date())
  const now = new Date()
  const diffInDays = differenceInDays(now, date)
  if (diffInDays === 0) {
    return 'dzisiaj'
  }
  if (diffInDays === 1) {
    return 'wczoraj'
  }
  if (diffInDays > 1) {
    return `${diffInDays} dni temu`
  }

  return diffInDays
}

onMounted(async () => {
  await getArticles()
  await getSocialMediaPosts()
  await getChartData()
})

import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const swiperModules = [Navigation, Pagination, Autoplay]
</script>

<template>
  <div>
    <!-- <div class="locale-changer">
      <select v-model="$i18n.locale">
        <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">
          {{ locale }}
        </option>
      </select>
    </div>
    <div>
      <input type="radio" name="locale" value="pl" v-model="$i18n.locale" />
      <input type="radio" name="locale" value="en" v-model="$i18n.locale" />
    </div> -->

    <!-- <h1 class="mb-16 border-b divide-solid border-mainGrey-200 pb-2">Notberg</h1> -->

    <div class="mb-16">
      <swiper
        :modules="swiperModules"
        :slides-per-view="1"
        :loop="true"
        :pagination="{ clickable: true }"
        :autoplay="{ delay: 5000, disableOnInteraction: false }"
        class="mySwiper"
      >
        <swiper-slide>
          <div
            class="w-full h-[420px] md:h-[340px] rounded-md bg-image bg-[url('@/assets/baner-1.jpg')] bg-cover bg-center flex justify-left items-center relative"
          >
            <div class="w-full h-full bg-black opacity-40 absolute rounded-md"></div>
            <div
              class="text-white text-left sm:text-[28px] lg:text-[36px] text-[28px] leading-[36px] lg:leading-[36px] font-semibold relative z-10 px-8 md:px-14"
            >
              Sprawdź, dlaczego<br />warto z nami pracować.
              <div class="text-sm font-light max-w-[500px] mt-4 w-[80%] md:w-[80%]">
                Oszczędzamy Twój czas, zajmując się formalnościami, byś mógł skupić się na biznesie.
                Z nami działasz sprawniej na rynkach zagranicznych.
              </div>
              <router-link to="/informacje/dlaczego-warto-z-nami-pracowac">
                <MainButton class="font-normal mt-8">Sprawdź</MainButton>
              </router-link>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div
            class="w-full h-[420px] md:h-[340px] rounded-md bg-image bg-[url('@/assets/baner-2.jpg')] bg-cover bg-right flex justify-left items-center relative"
          >
            <div
              class="text-black text-left sm:text-[28px] lg:text-[36px] text-[28px] leading-[36px] lg:leading-[36px] font-semibold relative z-10 px-8 md:px-14"
            >
              Pomagamy firmom<br />sprawnie uzyskać<br />wizy i dokumenty,
              <div class="text-sm font-light max-w-[800px] mt-4 w-[80%] md:w-[80%]">
                aby mogły działać bez przeszkód w Polsce i za granicą.<br />Legalizujemy dokumenty,
                dzięki czemu są one uznawane na całym świecie.
              </div>
              <router-link to="/informacje/jak-pracujemy">
                <MainButton class="font-normal mt-8">Więcej</MainButton>
              </router-link>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="mx-auto mt-16">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8 text-mainGrey-800">
        <div class="md:col-span-2 space-y-8">
          <div>
            <div class="flex justify-between items-center mb-4">
              <h2 class="text-xl font-bold">Ważne komunikaty</h2>
              <button
                @click="router.push({ name: 'articles' })"
                class="text-xs text-mainPrimary-700"
              >
                Zobacz wszystkie
              </button>
            </div>
            <div class="text-xs border border-mainGrey-200 bg-white px-4 rounded-md">
              <div class="divide-y divide-mainGrey-200">
                <div v-for="article in displayArticles" :key="article.id" class="py-4">
                  <p class="text-xs">{{ article.excerpt.slice(0, 250) + '...' }}</p>

                  <button
                    @click="router.push(`/artykuly/${article.slug}`)"
                    class="text-xs text-mainPrimary-700 underline cursor-pointer mt-2"
                  >
                    zobacz pełny artykuł
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div>
            <h2 class="text-xl font-bold mb-4">Śledź nas w mediach</h2>
            <div
              class="text-xs border border-mainGrey-200 p-4 rounded-md gap-x-4 gap-y-4 sm:gap-y-0 grid grid-cols-1 sm:grid-cols-3 bg-white"
            >
              <div
                v-for="post in socialMediaPosts"
                :key="post.id"
                class="flex flex-col items-start"
              >
                <div class="flex flex-row gap-x-2 items-center mb-3">
                  <img
                    :src="getIconAndText(post.platform).icon"
                    :alt="post.title"
                    class="w-6 h-6"
                  />
                  <p class="text-[12px] inline-block text-mainGrey-500">
                    {{ daysToNow(post.published_at) }}
                  </p>
                </div>
                <div class="flex flex-col justify-between h-full">
                  <p>{{ post.content.slice(0, 150) + '...' }}</p>
                  <a
                    v-if="post.url"
                    :href="post.url"
                    target="_blank"
                    class="text-xs text-mainPrimary-700 underline cursor-pointer mt-2"
                  >
                    {{ getIconAndText(post.platform).text }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="lg:col-span-1">
          <router-link to="/informacje/jak-dziala-aplikacja">
            <div
              class="bg-mainGreen-700 flex flex-row justify-center items-center leading-none rounded-md px-8 py-6 gap-x-4 mb-8 cursor-pointer"
            >
              <div class="text-white font-bold text-left text-xl">
                Zobacz jak działa nasza aplikacja
              </div>
              <div><PlayCircleIcon class="size-12 text-white" /></div>
            </div>
          </router-link>
          <div>
            <h2 class="text-xl font-bold mb-4">Aktualizacje</h2>
            <div class="text-xs border bg-mainGrey-700 text-white px-8 rounded-md py-6">
              <p class="text-center text-sm font-medium mt-2 mb-4">
                Stosunek krajów wymagających wizę do państw niewymagających wizy
              </p>
              <Doughnut :options="chartOptions?.doughnut" :data="chartData" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="w-full h-auto lg:h-[312px] mt-16 bg-mainGreen-700 rounded-md flex justify-left items-center relative lg:p-[80px] justify-center p-6"
    >
      <div class="grid items-center grid-cols-1 md:grid-cols-2">
        <div class="space-y-4 order-2 md:-order-1 flex justify-center flex-col">
          <span
            class="text-white sm:text-[36px] lg:text-[48px] text-[36px] leading-[48px] lg:leading-[56px] font-bold self-center md:self-start text-center md:text-left"
            >Zamów pełen<br />
            pakiet migracyjny</span
          >

          <router-link to="/informacje/pakiety-imigracyjne">
            <MainButton class="bg-white !text-mainGreen-700 hover:bg-mainGrey-100 md:self-start"
              >Zamów</MainButton
            >
          </router-link>
        </div>
        <div class="-mt-16"><img src="@/assets/trip.svg" alt="icon" /></div>
      </div>
    </div>
  </div>
</template>
<style>
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-next,
.swiper-button-prev {
  color: #fff;
}

.swiper-pagination-bullet-active {
  background: #fff;
}
</style>
